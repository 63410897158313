<!--消费模块--菜单设置-->
<template>
  <div
    id="menuSettings"
    v-loading="loading"
    :element-loading-text="$t('consume.a1')"
  >
    <div class="header">
      <my-headertitle>{{ $t("consume.a58") }}</my-headertitle>
    </div>
    <div class="main">
      <div class="upload">
        <div class="upload_select">
          <a-upload
            :file-list="fileList"
            :remove="handleRemove"
            :before-upload="beforeUpload"
            style="width: 350px"
          >
            <a-button>{{ $t("consume.a59") }} </a-button>
          </a-upload>
        </div>
        <div>
          <p>{{ $t("consume.a60") }}</p>
          <a-button
            type="primary"
            @click="handleUpload"
            :disabled="fileList.length == 0"
          >
            {{ $t("consume.a61") }}
          </a-button>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>
<script>
import headerTitle from "../../components/Title/headerTitle";
import { uploadMenuFile } from "../../api/consume";

export default {
  data() {
    return {
      fileList: [],
      loading: false,
    };
  },
  methods: {
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.fileList = [file];
      return false;
    },
    handleUpload() {
      this.loading = true;
      const { fileList } = this;
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("siteId", this.$route.query.id);
      uploadMenuFile(formData)
        .then((res) => {
          console.log(res);
          this.loading = false;
          if (res.errorCode == "00") {
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
  components: {
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
#menuSettings {
  width: 100%;
  height: 100%;
}
.header {
  padding: 0px 20px;
}
.main {
  padding: 20px 20px;
}
.upload {
  display: flex;
  padding: 10px;
  min-height: 84px;
  border: 1px solid rgba(12, 12, 12, 0.2);
}
.upload_select {
  width: 300px;
  margin-right: 50px;
}
p {
  font-size: 14px;
  margin-bottom: 10px;
  color: rgba(12, 12, 12, 0.65);
}
</style>
